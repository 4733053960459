.scrollup {
  position: fixed;
  right: 1rem;
  bottom: -20%;
  background-color: $orange;
  opacity: .8;
  padding: 5px 5px 0 5px;
  border-radius: .4rem;
  z-index: 10;
  transition: .4s;

  .scrollup__icon {
    font-size: 1.5rem;
    color: #FFF;
  }
}

.scrollup:hover {
  background-color: $orange;
}

// Show scroll
.show-scroll {
  bottom: 2.5rem;
}