.qualification__tabs {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 2rem;

  .qualification__button {
    font-size: $h3-font-size;
    cursor: pointer;
  }
  
  .qualification__button:hover {
    color: $orange;
  }
  
  .qualification__icon {
    font-size: 1.8rem;
    margin-right: 0.25rem;
  }
}

.qualification__data {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  column-gap: 1.5rem;

  .qualification__title {
    font-size: $normal-font-size;
    font-family: $body-font;
    font-size: $normal-font-size;
    width: 125px;
  }
  
  .qualification__subtitle {
    display: inline-block;
    font-size: $small-font-size;
    margin-bottom: 0.5rem;
  }
  
  .qualification__calender {
    font-size: $small-font-size;
    color: $text-color-dark;
    display: flex;
    flex-direction: row;

    .calendar__icon {
      height: 14px;
      width: 14px;
      margin-right: 2.5px;
    }
  }
  
  .qualification__rounder {
    display: inline-block;
    width: 13px;
    height: 13px;
    background-color: $purple;
    border-radius: 50%;
  }
  
  .qualification__line {
    display: block;
    width: 1px;
    height: 100%;
    background-color: $purple;
    transform: translate(6px, -10px);
  }
}

.qualification [data-content] {
  display: none;
}

.qualification__active[data-content] {
  display: block;
}

.qualification__button.qualification__active {
  color: $orange;
}