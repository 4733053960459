.cards {
  max-width: 1250px;

  .card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    background-color: white;
    border-radius: 5px;
    width: 380px;
    min-height: 450px;
    position: relative;
    margin-bottom: 40px;
  
    .card__container {
      display: grid;
      width: 100%;
      height: auto;
  
      .card__image {
        display: flex;
        justify-content: center;

        .image {
          border-radius: 5px 5px 0 0;
          height: 260px;
        }
      }
  
      .card__content {
        padding: 5px 16px;
        text-align: center;
  
        .card__header .coding__pill {
          border: none;
          background-color: #f4f4f4;
          padding: 5px 10px;
          text-align: center;
          text-decoration: none;
          display: inline-block;
          margin: 4px 2px;
          border-radius: 16px;
        }
  
        .card__title {
          padding: 10px;
          font-size: $h2-font-size;
          margin-top: 5px;
        }
  
        .card__footer {
          padding: 10px;

          .card__link {
            position: relative;
            margin: 5px;
            text-decoration: none;
            color: $text-color-dark;

            &:hover {
              color: $text-color-dark;
            }

            &::before {
              content: '';
              position: absolute;
              width: 100%;
              height: 2px;
              border-radius: 4px;
              background-color: $text-color-dark;
              top: 20px;
              bottom: 0;
              left: 0;
              transform-origin: right;
              transform: scaleX(0);
              transition: transform .3s ease-in-out;
            }
          
            &:hover::before {
              transform-origin: left;
              transform: scaleX(1);
            }
          }
        }
      }
    }
  }
}