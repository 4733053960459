.footer {
  height: 5rem;
  background-color: $orange;
  padding-top: 1.5rem;
  
  .footer__copy {
    font-size: $small-font-size;
    text-align: center;
  }

  .footer__copy {
    color: $text-color-light;
  }
}