.about.section {
  .about__container {
    .about__wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .about__text {
      display: flex;
      flex-direction: column;
      text-align: center;
      gap: 20px;
    }

    .image-border {
      width: 300px;
      height: 410px;
      background-color: $purple;
      background-image: url(../images/about.png);
      position: relative;
      z-index: 1;
      align-self: center;
      
      &:after {
        content: '';
        position: absolute;
        width: 290px;
        height:400px;
        left: 5px;
        top: 5px;
        background-image: url(../images/beige-paper.png);
        background-color: white;
      }
      
      .main-image {
        position: absolute;
        width: 290px;
        height:400px;
        left: 5px;
        top: 5px;
        background-image: url(../images/about.png);
        background-size: 300px 400px;
        background-position: center center;
        z-index: 2;
        transition: .5s cubic-bezier(.5,.03,.41,1);
      }
      
      &:hover > .main-image {
        transform: translate(-15px, -15px);
        transition: .5s cubic-bezier(.5,.03,.41,1);
      }
      
      &:hover > p.title:after {
        transform: scale3d(1, 1, 1);
        transition: .5s cubic-bezier(.5,.03,.41,1);
      }
    }
  }
}