@media screen and (min-width: 1024px) {
  html {
    body {
      .header {
        padding: 0;

        .nav {
          .nav__logo {
            position: relative;

            &:hover {
              color: $text-color-light;
            }

            &::before {
              content: '';
              position: absolute;
              width: 100%;
              height: 2px;
              border-radius: 4px;
              background-color: $text-color-light;
              top: 25px;
              bottom: 0;
              left: 0;
              transform-origin: right;
              transform: scaleX(0);
              transition: transform .3s ease-in-out;
            }
          
            &:hover::before {
              transform-origin: left;
              transform: scaleX(1);
            }
          }

          .nav__list {
            .nav__item {
              position: relative;

              &:hover {
                color: $text-color-light;
              }

              &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                border-radius: 4px;
                background-color: $text-color-light;
                top: 20px;
                bottom: 0;
                left: 0;
                transform-origin: right;
                transform: scaleX(0);
                transition: transform .3s ease-in-out;
              }
            
              &:hover::before {
                transform-origin: left;
                transform: scaleX(1);
              }
            }
          }
        }
      }

      .home.section .home__content {
        padding-top: 9rem;
      }

      .home__container .home__content {

        .home__social {
          transform: translateX(-6rem);
        }

        .home__data .home__title {
          font-size: 3rem;
        }

        .home__blob {
          width: 320px;
        }
      }

      .flex {
        flex-direction: row;
      }

      .about.section {
        .about__container.container {
          max-width: 1024px;

          .about__wrapper {
            flex-direction: row;
            justify-content: space-between;
            padding: 0 10px;
            
            .about__text {
              width: 560px;
              text-align: left;
              justify-content: center;
            }

            .image-border {
            width: 375px;
            height: 510px;
              
              &:after {
                width: 365px;
                height: 500px;
              }
              
              .main-image {
                width: 365px;
                height:500px;
                background-size: 375px 500px;
              }
            }
          }
        }
      }
    }
  }
}
