.section {
    padding: 4.5rem 0 4rem;
}

.home__container {
  gap: 1rem;

  .home__content {
    grid-template-columns: .5fr 3fr;
    padding-top: 3.5rem;
    align-items: center;
  
    .home__social {
      display: grid;
      grid-template-columns: max-content;
      row-gap: 1rem;
    
      .home__social-icon {
        font-size: 1.75rem;
        color: $orange;
        transition: transform 0.5s;
      }
      
      .home__social-icon:hover {
        color: #FC834A;
        transform: translateY(-3px);
      }
    }

    .home__blob {
      width: 200px;
      fill: $purple;
    }
    
    .home__blob-img {
      width: 170px;
    }

    .home__data {
      grid-column: 1/3;
    
      .home__title {
        font-size: $big-font-size;

        .title__color {
          color: $orange;
        }
      }
      
      .home__subtitle {
        font-size: $h2-font-size;
        color: $text-color-dark;
        margin-bottom: 0.75rem;

        .title__color {
          color: $orange;
        }
      }
      
      .home__description {
        margin-bottom: 2rem;
      }
    }
  }

  .home__scroll {
    display: none;
  
    .home__scroll-button {
      color: $orange;
      transition: .3s;
    }
    
    .home__scroll-button:hover {
      transform: translateY(.25rem);
    }
  
    .home__scroll-mouse {
      font-size: 2rem;
    }
  
    .home__scroll-name {
      font-size: $small-font-size;
      color: $text-color-dark;
      margin-right: 0.25rem;
    }
    
    .home__scroll-arrow {
      font-size: 1.25rem;
    }
  }
}