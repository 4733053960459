* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  background-image: url(../images/beige-paper.png);
  background-color: white;
  font-family: $body-font;
  font-size: 16px;
  line-height: 1.7;
  color: $text-color-dark;
}

h1, h2, h3, h4 {
  font-family: $special-font;
  color: $text-color-dark;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}


.loader-container {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

// Reusable CSS
.section {
  padding: 2rem 0 4rem;
  min-height: 100vh;
}

.section__title {
  font-size: $h1-font-size;
  color: $text-color-dark;
}

.section__subtitle {
  display: block;
  font-size: $normal-font-size;
  margin-bottom: 3rem;
}

.section__title, 
.section__subtitle {
  text-align: center;
}

// Lay-out
.container {
  max-width: 768px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.grid {
  display: grid;
  gap: 1.5rem;
  justify-content: center;
}

// // Scrollbar
// ::-webkit-scrollbar {
//   width: .60rem;
//   background-color: $scroll-bar-color;
//   border-radius: .5rem;
// }

// ::-webkit-scrollbar-thumb {
//   background-color: $scroll-thumb-color;
//   border-radius: .5rem;
// }

// ::-webkit-scrollbar-thumb:hover {
//   background-color: $text-color-light;
// }
