@media screen and (min-width: 568px) {
  html {
    body {
      .home.section {
        height: 115vh;
        
        .home__content {
          grid-template-columns: max-content 1fr 1fr;
          padding-top: 9.5rem;
        }

        .home__data {
          grid-column: initial;
        }

        .home__img {
          order: 1;
          justify-self: center;
        }
      }

      .cards {
        .card {
          width: 400px;
        }
      }

      .qualification__sections {
        display: grid;
        grid-template-columns: .6fr;
        justify-content: center;
      }

      .footer__container {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@media screen and (min-width: 768px) {
  html {
    body {
      margin: 0;

      .container {
        margin-left: auto;
        margin-right: auto;
      }

      .section {
        padding: 6rem 0 2rem;

        .section__subtitle {
          margin-bottom: 4rem;
        }
      }

      .header {
        top: 0;
        bottom: initial;

        .nav {
          height: calc(3rem + 1.5rem);
          column-gap: 1rem;

          .nav__list {
            display: flex;
            column-gap: 2rem;
          }

          .nav__icon,
          .nav__close,
          .nav__toggle {
            display: none;
          }
        }
      }

      .header,
      .main,
      .footer__container {
        padding: 0 1rem;
      }

      .home__container {
        row-gap: 5rem;

        .home__content {
          padding-top: 5.5rem;
          column-gap: 2rem;

          .home__social-icon {
            width: 20px;
            height: 20px;
            margin-left: 10px;
          }

          .home__blob {
            width: 270px;
          }
        }
      }

      .qualification__tabs {
        justify-content: center;

        .qualification__button {
          margin-right: 3rem;
        }
      }

      .qualification__sections {
        grid-template-columns: .6fr;
      }
    
      .footer__bg {

        .footer__container {
          grid-template-columns: repeat(3, 1fr);

          .footer__links {
            flex-direction: row;
            column-gap: 1.5rem;
          }
        
          .footer__socials {
            justify-self: flex-end;
          }
        
          .footer__copy {
            margin-top: 4.5rem;
          }
        }
      }
    }
  }
}