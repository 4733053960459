// Button Dark/Light
.nav__btns {
  display: flex;
  align-items: center;
}

.change-theme {
  font-size: 1.25rem;
  color: $text-color-dark;
  margin-right: 1rem;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.change-theme:hover {
  color: $orange;
}

// Buttons
.button {
  display: inline-block;
  background: $purple;
  color: $text-color-light;
  padding: 1rem;
  border-radius: .5rem;
}

.button--flex {
  display: inline-flex;
  align-items: center;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
}

.button--white {
  background-color: #FFF;
  color: $purple;
}

.button--small {
  padding: .75rem 1rem;
}

.button--link {
  padding: 0;
  background-color: transparent;
  color: $orange;
}

.button__icon {
  font-size: 1.25rem;
  margin-left: 0.5rem;
  transition: .3s;
}

// .button:hover {
//   background-color: #7C1354;
// }

.button:active {
  transform: scale(0.98);
  /* Scaling button to 0.98 to its original size */
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  /* Lowering the shadow */
}

.button--white:hover {
  background-color: #FFF;
}

.button--link:hover {
  background-color: transparent;
  color: $orange;
}