.nav {
  max-width: 968px;
  height: 3.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .nav__logo,
  .nav__toggle {
    color: $text-color-light;
  }

  .nav__logo:hover {
    color: $orange;
  }

  .nav__toggle,
  .change-theme {
    margin-top: 5px;
    height: 18px;
    width: 18px;
    cursor: pointer;
  }

  .change-theme.hidden {
    display: none;
  }

  .nav__toggle:hover {
    color: $orange;
  }

  .nav__list {
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  
    .nav__link {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: $small-font-size;
      color: $text-color-light;
    }

    // Active link
    .active-link {
      color: $text-color-light;
    }
    
    .nav__icon {
      font-size: 1.2rem;
    }
  }
  
  .nav__close {
    position: absolute;
    right: 1.3rem;
    bottom: .5rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: $text-color-light;
    margin-bottom: 1rem;
  }
  
  .nav__close:hover {
    color: $orange;
  }
  
  // Show menu
  .show-menu {
    top: 0;
  }
}

// Change background header
.scroll-header {
  box-shadow: 0 -1px 4px rgba(0,0,0,.15);
  position: fixed;
  top: 0;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .nav__menu {
    position: fixed;
    z-index: 100;
    top: -100%;
    left: 0;
    width: 100%;
    background-color: $purple;
    padding: 2rem 1.5rem 4rem;
    box-shadow: 0px 2px 7px -1px rgba(0,0,0,1);
    border-radius: 0 0 1.5rem 1.5rem;
    transition: .3s;
  }
}