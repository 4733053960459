// Font and typography
$body-font: 'Cutive Mono', sans-serif;
$special-font: 'Fredericka the Great', sans-serif;

$big-font-size: 2rem;
$h1-font-size: 1.5rem;
$h2-font-size: 1.25rem;
$h3-font-size: 1.125rem;
$normal-font-size: 1rem;
$small-font-size: .75rem;

// Colors
$text-color-dark: #001219;
$text-color-light: #ffd8d1;
$green: #A9B388;
$orange: #F14F04;
$purple: #5f0f40;
$dark-purple: #470B30;
