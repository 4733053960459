@media screen and (max-width: 350px) {
  html {
    body {
      .container {
        margin-left: 1rem;
        margin-right: 1rem;
      }

      .nav__menu {
        padding: 2rem .25rem 4rem;

        .nav__list {
          column-gap: 0;
        }
      }

      .home.section {
        height: 115vh;

        .home__content {
          grid-template-columns: .25fr 3fr;

          .home__blob {
            width: 200px;
          }
        }
      }

      .qualification__data .qualification__title {
        width: 65px;
      }
    }
  }
}